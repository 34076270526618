<template>
  <div>
    <headertop />
    <div>
      <div>
        <img style="width: 100%" src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/carbanner.png" alt="" />
      </div>
      <div class="carinformation">
        <div class="car">
          <ul>
            <li>
              <h5>宁<span class="tuomin">***</span>279</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***621</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***076</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***332</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="car">
          <ul>
            <li>
              <h5>宁***730</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***118</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***098</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>陕***995</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="car">
          <ul>
            <li>
              <h5>陕***994</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***227</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***822</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***391</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="car" style="margin-bottom: 20px">
          <ul>
            <li>
              <h5>宁***105</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 天然气</span>
              </p>
            </li>
            <li>
              <h5>宁***619</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 柴油</span>
              </p>
            </li>
            <li>
              <h5>宁***105</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 柴油</span>
              </p>
            </li>
            <li>
              <h5>宁***700</h5>
              <p>
                车辆类型：<span>重型半挂牵引车</span> <br />
                能源类型：<span> 柴油</span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage3"
      :page-size="100"
      layout="prev, pager, next, jumper"
      :total="1000">
    </el-pagination> -->
    </div>
    <bottombar />
  </div>
</template>
<script>
// @ is an alias to /src
import headertop from "@/components/headertop.vue";
import bottombar from "@/components/bottombar.vue";
export default {
  name: "Home",
  components: {
    headertop,
    bottombar,
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  data() {
    return {
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
    };
  },
};
</script>
<style scoped>
.carinformation {
  /* height: 1400px; */
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0;
}
.car {
  width: 1200px;
  height: 312px;
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
}
.car ul {
  width: 1200px;
  height: 312px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.car ul li {
  width: 280px;
  margin-left: 20px;
  height: 312px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/车辆.png) no-repeat 32px 47px;
  background-color: white;
  padding-left: 31px;
}
.tuomin{
  display: inline-block;
}
.car ul li:hover{
  border-bottom: 10px solid #4272C3;
  box-shadow: 0px 0px 34px 1px rgba(214, 214, 214, 0.64);
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/icon1.png) no-repeat 32px 47px;
  background-color: white;
}
.car ul li h5 {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 137px;
}
.car ul li p {
  color: #999999;
  line-height: 30px;
  margin-top: 10px;
}
.car ul li p span {
  color: black;
}
.el-pagination {
  text-align: center;
  margin-bottom: 20px;
}
</style>